<template>
  <!--  社区首页内容发布-->
  <div class="communityContent">
    <div class="communityContent_header">
      <div>
        操作日期：<a-range-picker
          v-model="timeVal"
          @change="onChange_range_picker"
        />
        &ensp;&ensp;&ensp;
        <a-button type="primary" @click="search"> 查询 </a-button>
      </div>
      <a-button @click="expotExcel()">
        <a-icon type="file-excel" style="color: green" /> 批量导出</a-button
      >
    </div>
    <div class="communityContent_center">
      <a-alert
        message="该界面仅展示通过社区发布的内容浏览记录（文本、图片、视频、外链），通过其他模块推荐到社区首页的内容，内容浏览量请到对应模块查看"
        type="info"
        show-icon
      />
      <a-table
        :columns="columns"
        style="min-height: 500px; background-color: #fff; margin-top: 15px"
        :data-source="dataSource"
        :rowKey="(record) => record.id"
        :pagination="false"
        :scroll="{ x: 1500 }"
      >
        <span slot="NUM" slot-scope="text, item, index">
          <span>{{
            text == "LINK"
              ? "外链"
              : text == "TEXT"
              ? "文字"
              : "text" == "VIDEO"
              ? "视频"
              : "图片"
          }}</span>
        </span>
        <span slot="location" slot-scope="text, record">
          <span>{{ returnTitle(text) }}</span>
        </span>
        <span slot="title1" slot-scope="text, record">
          <a-tooltip>
            <template slot="title"> {{ text }}</template>
            <span class="multi-ellipsis">{{ text }}</span>
          </a-tooltip>
        </span>
      </a-table>
      <div
        style="
          margin-top: 28px;
          margin-left: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <span
          style="font-size: medium; color: #929292; font-size: 14px; bottom: 3%"
        >
          共{{ total }}条记录 第 {{ pageNo }} /
          {{ Math.ceil(total / pageSize) }} 页
        </span>
        <a-pagination
          style="float: right"
          show-quick-jumper
          show-size-changer
          :page-size.sync="pageSize"
          :total="total"
          v-model="pageNo"
          @change="pageChange"
          @showSizeChange="pageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getReadRecord, getExport } from "@/service/homePageFeatures_api";
import codeList from "@/assets/configurationFiles/EduDrugCodeList.json";
export default {
  name: "homeReadRecord",
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      total: 0,
      dataSource: [],
      timeVal: [],
      StartTime: "",
      EndTime: "",
      columns: [
        {
          title: "姓名",
          dataIndex: "name",
          key: "name",
          width: "6%;",
        },
        {
          title: "医院",
          dataIndex: "hospitalName",
          key: "hospitalName",
          width: "8%;",
        },
        {
          title: "科室",
          dataIndex: "hospitalOffices",
          key: "hospitalOffices",
          width: "8%;",
        },
        {
          title: "职称",
          dataIndex: "jobTitle",
          key: "jobTitle",
          width: "6%",
        },
        {
          title: "类型",
          dataIndex: "type",
          key: "type",
          width: "5%;",
          scopedSlots: { customRender: "NUM" },
        },
        {
          title: "标题",
          dataIndex: "title",
          key: "title",
          width: "23%",
          scopedSlots: { customRender: "title1" },
        },
        {
          title: "点击位置",
          dataIndex: "location",
          key: "location",
          width: "8%;",
          scopedSlots: { customRender: "location" },
        },
        {
          title: "渠道",
          dataIndex: "channel",
          key: "channel",
          width: "6%;",
        },
        {
          title: "操作时间",
          dataIndex: "createdTime",
          key: "createdTime",
          width: "12%;",
        },
      ],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    returnTitle(code) {
      let list = codeList;
      for (let i = 0; i < list.length; i++) {
        if (code === list[i].code) {
          return list[i].title;
        }
      }
    },
    async load() {
      const res = await getReadRecord(
        this.pageNo,
        this.pageSize,
        this.StartTime,
        this.EndTime
      );
      if (res.code == 0) {
        this.dataSource = res.data;
        this.total = res.count;
      }
    },
    // 分页查询
    pageChange(pageNo, pageSize) {
      this.pageNo = pageNo;
      this.pageSize = pageSize;
      this.load();
    },
    onChange_range_picker(date, dateString) {
      this.StartTime = dateString[0];
      this.EndTime = dateString[1];
      this.pageSize = 10;
      this.pageNo = 1;
    },
    search() {
      
      this.load();
    },
    // 导出
    async expotExcel() {
      const data = {
        startTime: this.StartTime,
        endTime: this.EndTime,
        ExelName: "社区帖子浏览记录",
      };
      const response = await getExport(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.liBackground {
  color: #4a9bfa !important;
}
.communityContent {
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);

  .communityContent_header {
    background: white;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
  }

  .communityContent_center {
    margin-top: 10px;
    background: white;
    padding: 15px;
    .timeIndex {
      display: flex;
      cursor: pointer;
      align-items: center;
      .title {
        margin-right: 5px;
      }
      ::v-deep .anticon {
        color: #aba9a9;
        font-size: small;
      }
    }
  }
}
.multi-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
}
</style>
